<template>
    <router-link :to="'/productDetail?id=' + this.info.id">
        <el-card :body-style="{ padding: '0px' }" :class="{ active: showMore }">
            <div class="row">
                <div class="top">
                    <div
                        class="img bg"
                        :style="{
                            backgroundImage: `url(${img})`
                        }"
                    ></div>
                </div>
                <div class="info">
                    <div class="name">
                        <span class="text" v-html="getImport(name)"> </span>

                        <div @click.stop.prevent="collection">
                            <span class="iconfont" :class="{ active: isCollection }">{{
                                isCollection ? '&#xe8c3;' : '&#xe616;'
                            }}</span>
                        </div>
                        <el-popover placement="bottom" trigger="hover">
                            <span class="iconfont" @click.stop.prevent="" slot="reference">&#xe617;</span>

                            <share :info="info" v-show="!isChinese"></share>
                            <share :info="info" local="en" v-show="!isChinese"></share>
                        </el-popover>
                    </div>

                    <div class="sub" v-html="getImport(getName(info, ['model', 'enModel']))"></div>

                    <div class="flex1"></div>

                    <div class="info-item" v-if="info.averageLeadTime">
                        <div class="info-name">
                            {{ $t('ping-jun-jiao-huo-shi-jian') }}
                        </div>
                        <div class="info-val">{{ info.averageLeadTime }}</div>
                    </div>

                    <div class="info-item" v-if="tag.length > 0">
                        <div class="info-name">
                            {{ $t('chan-pin-biao-qian') }}
                        </div>
                        <div class="info-val">
                            <el-tag v-for="(item, index) in tag" :key="index" type="info" effect="plain" size="small">
                                {{ item }}
                            </el-tag>
                        </div>
                    </div>
                </div>
                <div class="right">
                    <!-- <el-row>
                        <el-button type="text">
                            <div class="btn-content">
                                <span class="iconfont">&#xe772;</span>
                                <span>关注</span>
                            </div>
                        </el-button>
                        <el-button type="text">
                            <div class="btn-content">
                                <span class="iconfont">&#xe6c0;</span>
                                <span>分享</span>
                            </div>
                        </el-button>
                    </el-row> -->

                    <Logo :logo="info.logo1 + '?x-oss-process=image/resize,h_204,m_lfit'" />
                    <el-row type="flex" class="right-normal">
                        <el-button type="info" plain @click.stop.prevent="addIntentionList(info.id)">
                            {{ $t('tian-jia-yi-xiang-dan') }}</el-button
                        >
                        <el-button type="info" plain @click.stop.prevent="addContrast(info.id)">
                            {{ $t('tian-jia-dui-bi') }}</el-button
                        >
                        <el-button type="warning" plain @click.stop.prevent="advisory(info)">
                            {{ $t('kuai-su-xun-jia') }}</el-button
                        >
                    </el-row>
                    <el-popover class="btn-popover" placement="bottom" trigger="hover" v-model="showMore">
                        <el-button
                            icon="el-icon-more"
                            :class="{ active: showMore }"
                            class="more-btn"
                            slot="reference"
                            type="text"
                        ></el-button>

                        <el-row type="flex">
                            <el-button type="info" plain @click.stop.prevent="addIntentionList(info.id)">
                                {{ $t('tian-jia-yi-xiang-dan') }}</el-button
                            >
                            <el-button type="info" plain @click.stop.prevent="addContrast(info.id)">
                                {{ $t('tian-jia-dui-bi') }}</el-button
                            >
                            <el-button type="warning" plain @click.stop.prevent="advisory(info)">
                                {{ $t('kuai-su-xun-jia') }}</el-button
                            >
                        </el-row>
                    </el-popover>
                </div>
            </div>
        </el-card>
    </router-link>
</template>
<script>
import Logo from './Logo';
import UserStoreOperation from '../../mixins/UserStoreOperation';
import Share from '../Share.vue';
export default {
    name: 'Row',
    mixins: [UserStoreOperation],
    props: {
        isNew: {
            type: Boolean,
            default: true
        },
        info: {
            type: Object,
            default: () => {
                return {};
            }
        },
        search: {
            type: String,
            default: ''
        }
    },
    watch: {
        info() {
            this.$nextTick(() => {
                this.cId = this.info.id;
                this.isCollection = this.info.isCollect;
            });
        }
    },
    data() {
        return {
            collectionType: 'PRODUCT',
            needCollect: false,
            showMore: false
        };
    },
    mounted() {
        if (this.info.id) {
            this.cId = this.info.id;
            this.isCollection = this.info.isCollect;
        }
    },
    computed: {
        name() {
            return this.$i18n.locale.indexOf('zh') !== -1 ? this.info.chName : this.info.enName;
        },
        tag() {
            return (
                this.info.tag
                    .filter(item => {
                        return !!item;
                    })
                    .map(item => {
                        return this.getName(item);
                    })
                    .slice(0, 3) || []
            );
        },
        img() {
            if (this.info.img) {
                return this.info.img.split(',')[0];
            } else {
                return '';
            }
        },
        config() {
            return {
                url: 'https://www.imttech.cn/index/productDetail?id=' + this.name.id,
                title: this.name, // 标题，默认读取 document.title 或者 <meta name="title" content="share.js" />
                description: this.getName(this.info, ['model', 'enModel']), // 描述, 默认读取head标签：<meta name="description" content="PHP弱类型的实现原理分析" />
                image: this.img, // 图片, 默认取网页中第一个img标签
                sites: ['qq', 'weibo', 'wechat', 'facebook', 'twitter'], // 启用的站点
                disabled: [], // 禁用的站点
                wechatQrcodeTitle: this.$t('wei-xin-sao-yi-sao-fen-xiang'),
                wechatQrcodeHelper:
                    '<p>' +
                    this.$t('wei-xin-li-dian-fa-xian-sao-yi-xia') +
                    '</p><p>' +
                    this.$t('er-wei-ma-bian-ke-jiang-ben-wen-fen-xiang-zhi-peng-you-quan') +
                    '</p>'
            };
        }
    },
    methods: {
        goDetail() {
            this.$router.push('/productDetail?id=' + this.info.id);
        }
    },
    components: {
        Logo,
        Share
    }
};
</script>
<style lang="scss" scoped>
@import '../../styles/variables';
.row {
    display: flex;
    overflow: hidden;
}
.el-card {
    transition: all ease-in-out 0.3s;
    box-shadow: none;
    position: relative;
    box-sizing: border-box;
    transform: translate3d(0, 0, 0);

    &:hover {
        box-shadow: 0px 8px 17px 0px rgba(255, 165, 38, 0.12);
        border: 1px solid rgba(255, 165, 38, 1);
        transform: scaleX(1.02);
        // .el-row {
        //     display: flex;
        // }

        // .name {
        //     .iconfont {
        //         display: none;
        //     }
        // }
    }

    &.active {
        box-shadow: 0px 8px 17px 0px rgba(255, 165, 38, 0.12);
        border: 1px solid rgba(255, 165, 38, 1);
        transform: scaleX(1.02);
        // .el-row {
        //     display: flex;
        // }
    }
}
.new-img {
    position: absolute;
    top: 0;
    left: 10px;
    width: 30px;
    height: 30px;
}
.top {
    // padding: 26px;
    .img {
        width: 240px;
        height: 240px;
    }
}

.name {
    font-size: 21px;
    color: $--color-text-regular;
    line-height: 29px;
    font-weight: bold;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: flex;
    align-items: center;
    margin-bottom: 4px;
    display: flex;

    .text {
        margin-right: 20px;
        flex-grow: 1;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .iconfont {
        display: block;
    }
}

.sub {
    font-size: 15px;
    font-weight: bold;
    color: #878d99;
    line-height: 23px;
}

.el-card {
    cursor: pointer;
}

.info {
    padding: 30px 20px 45px;
    position: relative;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.sub {
    display: flex;
    align-items: center;
    font-size: 13px;
    color: rgba(86, 91, 102, 1);
    line-height: 24px;
    margin-top: 7px;
}
.iconfont {
    color: #dcdfe6;
    font-size: 28px;
    display: block;
    margin-right: 10px;
    cursor: pointer;
    font-weight: normal;
    &:hover {
        color: $--color-warning;
    }

    &.active {
        color: $--color-warning;
    }
}

.right {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding: 30px 30px 50px;
    justify-content: space-between;
    position: relative;
    flex-shrink: 0;

    .logo {
        width: 143px;
    }
}

.el-row {
    .el-button {
        background-color: transparent;
        padding: 10px 15px;

        &.el-button--warning.is-plain {
            min-width: 132px;
            &:hover {
                background-color: $--color-warning;
            }
        }

        &.el-button--info.is-plain {
            border-color: #dcdfe6;
            color: #565b66;
            min-width: 100px;
            &:hover {
                background-color: #dcdfe6;
                color: #565b66;
            }
        }
    }
}

.el-tag {
    font-size: 13px;
    margin-right: 10px;
}

.info-item {
    display: flex;
    align-items: center;

    .info-name {
        font-size: 13px;
        color: rgba(188, 193, 204, 1);
        line-height: 33px;
        margin-right: 20px;
        flex-shrink: 0;
        min-width: 78px;
    }

    .info-val {
        font-size: 12px;
        color: rgba(86, 91, 102, 1);
        line-height: 22px;
        display: flex;
        flex-wrap: nowrap;
        overflow: hidden;
    }
}

.btn-content {
    display: flex;
    align-items: center;
    font-size: 13px;

    color: #bcc1cc;
    .iconfont {
        margin-right: 0px;
    }
}
.el-button--text {
    padding: 14px 20px;
    &:hover {
        .btn-content {
            color: $--color-warning;

            .iconfont {
                color: $--color-warning;
            }
        }
    }
}

.more-btn {
    &.active {
        color: $--color-warning;
    }
}

@media screen and (min-width: 1400px) {
    .right-normal {
        display: flex;
    }
    .btn-popover {
        display: none;
    }
}

@media screen and (max-width: 1400px) {
    .right-normal {
        display: none;
    }
    .btn-popover {
        display: block;
    }
}
</style>
